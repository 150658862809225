import { EntityResponse } from './delivery-utils';

const handleError = <T>(response: any): EntityResponse<T> => ({
  failed: Boolean(!response?.succeeded && response?.errors?.[0]?.description),
  error: response?.errors?.[0]?.description,
  data: response,
});

export {
  handleError,
};
