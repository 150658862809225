import { create } from 'zustand';
import { User } from '../models/User';

interface AccountState {
    isAuthenticated: boolean
    user?: User;
}

const useAccountStore = create<AccountState>()(() => ({
  isAuthenticated: false
}));

const setUser = (user: User) => {
  useAccountStore.setState(() => ({
    user,
    isAuthenticated: true
  }));
};

export { useAccountStore, setUser };
