import { Stack, Typography, Box } from '@mui/material';
import { EditPenIcon, LocationIcon, ZoneIcon } from 'views/components/icon';
import './settings.scss';
import { Button, Table } from 'views/components/elements';
import { WarehouseTab } from './components/WarehouseTab';
import { WarehouseIcon } from 'views/components/icon';
import { useWarehouseStore } from 'store/warehouse';
import HeaderWithAction from 'views/components/elements/HeaderWithAction';
import { useEffect, useRef, useState } from 'react';
import { Warehouse, Zone } from 'models';
import WarningCard from 'views/components/elements/WarningCard';
import WarehouseForm from 'views/pages/settings/forms/WarehouseForm';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import CustomAccordion from 'views/components/elements/CustomAccordian';
import { BodyText, TitleText } from '../delivery/StyledComponent';
import IOSSwitch from 'views/components/elements/CustomSwitch';
import { fetchZones } from 'gateways/warehouse';
import { handleError } from 'views/shared/utils/error-utils';
import { EntityResponse } from 'views/shared/utils/delivery-utils';

type PopUpFormTypes = 'warehouse' | 'zone' | 'location';
type PopUpState = 'open' | 'close';

const Settings: React.FC = () => {
  const { selectedWarehouse, fetchWarehouses, resetData } = useWarehouseStore();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const isInitialFetchCompleted = useRef(false);
  const [zones, setZones] = useState<Zone[]>([]);
  const [isZoneDataFetched, setIsZoneDataFetched] = useState(false);

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchWarehouses().then(() => setIsDataFetched(true));
      isInitialFetchCompleted.current = true;
    }

    return () => resetData();
  }, []);

  const fetchZoneDetails = async (warehouse_id: number): Promise<EntityResponse<Zone[]>> => {
    const response = await fetchZones(warehouse_id);
    return handleError<Zone[]>(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedWarehouse?.warehouse_id) {
        const { failed, data } = await fetchZoneDetails(selectedWarehouse.warehouse_id);
        setIsZoneDataFetched(true);

        if (!failed) {
          setZones(data);
        }
      }
    };

    fetchData();

    return () => {
      setZones([]);
      setIsZoneDataFetched(false);
    };
  }, [selectedWarehouse]);

  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditForm, setIsEditForm] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState<{
    warehouse?: boolean;
    zone?: boolean;
    location?: boolean;
  }>({});

  const handlePopUp =
    (popUp: PopUpFormTypes, type: PopUpState, isEdit?: boolean) => () => {
      setIsEditForm(Boolean(isEdit));

      setIsPopUpOpen({
        [popUp]: type === 'open',
      });
    };

  const handleFormSubmit =
    (formType: PopUpFormTypes) => (failed: boolean, error: string) => {
      if (failed) {
        setErrorMessage(error);
        setIsErrorPopUpOpen(true);
        return;
      }

      handlePopUp(formType, 'close', false)();
    };
  const columns = [
    {
      field: 'location_code',
      label: 'Location Code',
      width: 120,
    },
    {
      field: 'location_name',
      label: 'Location Name',
      width: 120,
    },
    {
      field: 'notes',
      label: 'Notes',
      width: 200,
      valueGetter: (row: any) => (
        <LocationNotes notes={row.notes} switchChecked={row.switchChecked} />
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      location_code: 'LOC001',
      location_name: 'Receiving Area',
      notes:
        'The warehouse consists of locations, such as bins, spaces on a rack, pallet spaces on the floor.',
      switchChecked: false,
    },
    {
      id: 2,
      location_code: 'LOC002',
      location_name: 'Storage Area',
      notes:
        'Reserved for storing products before they are moved to other areas.',
      switchChecked: false,
    },
    {
      id: 3,
      location_code: 'LOC003',
      location_name: 'Shipping Area',
      notes: 'Designated area for packing and shipping orders.',
      switchChecked: false,
    },
  ];

  const LocationNotes: React.FC<{ notes: string; switchChecked: boolean }> = ({
    notes,
    switchChecked,
  }) => (
    <Box display='flex' alignItems='center'>
      <Typography style={{ fontWeight: 'normal', fontSize: '12px', flex: 1 }}>
        {notes}
      </Typography>
      <Box display='flex' alignItems='center' marginLeft='auto'>
        <IOSSwitch
          defaultChecked={switchChecked}
          size='small'
          sx={{ transform: 'scale(0.75)' }}
        />
        <Button
          variant='text'
          style={{ fontWeight: 'normal', fontSize: '12px' }}
          endIcon={<EditPenIcon />}
        />
      </Box>
    </Box>
  );

  const AccordionSection: React.FC<{
    zones: Zone[];
    rows: any[];
    columns: any[];
  }> = ({ zones, rows, columns }) => (
    <Box
      sx={{
        maxHeight: '300px',
        overflowY: 'auto', 
      }}
    >
      {zones.map(({zone_id, zone_code, zone_name, notes, is_active}) => (
        <CustomAccordion key={zone_id} title={`Zone-${zone_code}`} switchChecked={is_active}>
          <BodyText>{zone_name}</BodyText>
          <BodyText>{notes}</BodyText>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
          >
            <TitleText>Location</TitleText>
            <Button
              variant='contained'
              startIcon={<LocationIcon />}
              style={{ height: '40px', marginLeft: 'auto' }}
            >
              Add Location
            </Button>
          </Box>
          <Box className='location-table-container' sx={{ marginTop: '16px' }}>
            <Table rows={rows} columns={columns} infiniteScroll />
          </Box>
        </CustomAccordion>
      ))}
    </Box>
  );

  return (
    <>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
        durationInSeconds={5}
      />
      <Stack spacing={5} className='settings-container'>
        <WarehouseTab>
          <Button
            variant='contained'
            color='primary'
            startIcon={<WarehouseIcon />}
            className='height-40px'
            onClick={handlePopUp('warehouse', 'open', false)}
          >
            Add Warehouse
          </Button>
        </WarehouseTab>
        {isDataFetched && selectedWarehouse?.warehouse_id && (
          <Stack spacing={5}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <HeaderWithAction
                headerFontSize='32px'
                showIcon={true}
                content={`Warehouse-${selectedWarehouse.warehouse_code}`}
                onIconClick={handlePopUp('warehouse', 'open', true)}
              />
              <Button
                variant='contained'
                color='primary'
                startIcon={<ZoneIcon />}
                className='height-40px'
                onClick={() => null}
              >
                Add Zone
              </Button>
            </Stack>
          </Stack>
        )}
        {isDataFetched && !selectedWarehouse?.warehouse_id && (
          <p>No Warehouse data found</p>
        )}
        {isDataFetched && selectedWarehouse?.warehouse_id && (
          <p>{selectedWarehouse.warehouse_name}</p>
        )}
        <PopUpDialog
          open={Boolean(isPopUpOpen.warehouse)}
          onClose={handlePopUp('warehouse', 'close')}
          title={`${isEditForm ? 'Edit' : 'Create'} Warehouse`}
        >
          <WarehouseForm
            value={
              isEditForm ? selectedWarehouse : ({} as unknown as Warehouse)
            }
            onSubmit={handleFormSubmit('warehouse')}
            onClose={handlePopUp('warehouse', 'close')}
            edit={isEditForm}
          />
        </PopUpDialog>
        { isZoneDataFetched && zones.length > 0 && <AccordionSection
          zones={zones}
          rows={rows}
          columns={columns}
        /> }
        { isZoneDataFetched && zones.length === 0 && <p>No Zone data found</p> }
      </Stack>
    </>
  );
};

export default Settings;
