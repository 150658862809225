import React, { useMemo, useState } from 'react';
import { TextField, debounce as debounceFunction, TextFieldProps } from '@mui/material';
import './index.scss';

interface SearchFieldProps extends Omit<TextFieldProps<'outlined'>, 'onChange' | 'variant'> {
    handleSearch?: TextFieldProps['onChange'];
    debounce?: number;
    addon?: React.ReactNode;
    addonPosition?: 'start' | 'end';
}

const DebounceInput: React.FC<SearchFieldProps> = ({
  handleSearch,
  debounce,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debounceSearch(event);
  };
  
  const debounceSearch = useMemo(() => debounceFunction((event) => {
    // Perform your search logic or API call here
    return handleSearch(event);
  }, debounce || 500), // Adjust the debounce delay as per your requirements
  [handleSearch]);
  
  return (
    <TextField
      variant='outlined'
      {...rest}
      className={['custom-debounce-input', rest?.className].filter(el => el).join(' ')}
      value={searchTerm}
      onChange={handleSearchChange}
    />
  );
};

export default DebounceInput;
