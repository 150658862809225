import Select, { SelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Field } from 'formik';

const StyledSelect = styled(Select)({
  '& .MuiInputBase-input': {
    padding: '9.5px 32px 9.5px 12px',
  },
});

export default function CustomizedSelect(props: SelectProps) {
  const { name, label, ...rest } = props;

  const labelId = crypto.randomUUID();

  return (
    <Field name={name}>
      {({ field }) =>
        (label && (
          <FormControl>
            <InputLabel size='small' id={labelId}>{label}</InputLabel>
            <StyledSelect
              labelId={labelId}
              label={label}
              {...field}
              {...rest}
            />
          </FormControl>
        )) || <StyledSelect {...field} {...rest} />
      }
    </Field>
  );
}
