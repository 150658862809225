import { styled } from '@mui/material/styles';
import BaseAvatar from '@mui/material/Avatar';

export const FormField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
  '& label': {
    fontSize: 14,
  },
});

export const Action = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0',
  '& form': {
    display: 'flex',
    columnGap: 16,
    alignItems: 'center',
  },
});

export const Avatar = styled(BaseAvatar)({
  backgroundColor: '#0f172a',
  height: 56,
  width: 56,
  fontSize: 24,
  fontWeight: '600'
});

export const Title = styled('span')({
  fontSize: 20,
  fontWeight: '500'
});

export const Subtitle = styled('span')({
  fontSize: 13,
  fontWeight: '600'
});

export const VerticalDivider = styled('div')({
  width: 1,
  backgroundColor: '#d9d9d9',
  height: 72
});

export const AmountLabel = styled('label')({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
});

export const MetadataKey = styled('td')({
  fontSize: 14,
  fontWeight: 600,
  paddingRight: 8,
  paddingBottom: 8
});

export const MetadataValue = styled('td')({
  fontSize: 14,
  paddingBottom: 8
});
