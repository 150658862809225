import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')({
  height: '100%',
  overflowY: 'auto',
  marginRight: '-36px',
  paddingRight: 36,
  display: 'flex',
  flexDirection: 'column',
  '& h1': {
    marginBottom: 16,
  },
});

export const ScrollablePage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  overflowY: 'auto',
  marginRight: '-36px',
  paddingRight: '34px',
});
