// import * as CSS from 'csstype';
import { createTheme } from '@mui/material/styles';
// import { mediaQuery } from './viewport';

// export type CSSProperties = CSS.Properties<string | number>;
// export type CSSPseudos = { [K in CSS.Pseudos]?: CSSObject };
// export interface CSSObject extends CSSProperties, CSSPseudos {
//   [key: string]: CSSObject | string | number | undefined;
// }

// export type STYLES = CSSObject;

// declare module '@mui/material/styles' {
//   // type declarations for custom theme
//   interface Theme {
//     colors: {
//       [key: string]: CSSObject['color']
//     },
//     general: {
//       fontFamily: string;
//     },
//     componentCustomStyles: {
//       h1: STYLES;
//       h2: STYLES;
//       h3: STYLES;
//       h4: STYLES;
//       h5: STYLES;
//       h6: STYLES;
//       p: STYLES;
//       pre: STYLES;
//       span: STYLES;
//       link: STYLES;
//       label: STYLES;
//       subtitle1: STYLES;
//       subtitle2: STYLES;
//       body1: STYLES;
//       body2: STYLES;
//       caption: STYLES;
//       overline: STYLES;
//       [ComponentName: string]: STYLES;
//     };
//   }

//   interface ThemeOptions {
//     colors: {
//       [key: string]: CSSObject['color']
//     },
//     general: {
//       fontFamily: string;
//     },
//     componentCustomStyles: {
//       h1: STYLES;
//       h2: STYLES;
//       h3: STYLES;
//       h4: STYLES;
//       h5: STYLES;
//       h6: STYLES;
//       p: STYLES;
//       pre: STYLES;
//       span: STYLES;
//       link: STYLES;
//       label: STYLES;
//       subtitle1: STYLES;
//       subtitle2: STYLES;
//       body1: STYLES;
//       body2: STYLES;
//       caption: STYLES;
//       overline: STYLES;
//       [ComponentName: string]: STYLES;
//     };
//   }
// }

// // tyography style that appplies for all variants of typography texts
// export const commonTypographyStyles: CSSObject = {
//   fontFamily: '\'Rubik\', sans-serif',
// };

// // custom defined colors for theme
// const themeColors = {
//   default: '#0f172a',
//   black: '#0f172a',
//   primary: '#3460dc'
// };

// // common theme options like color palatte will be customized here
// const commonStylesForAllComponents = createTheme();

// // for later use
// // customizations for material ui components
// const customization: ThemeOptions = {
//   colors: themeColors,
//   typography: {
//     fontFamily: 'Rubik, sans-serif'
//   },
//   general: {
//     fontFamily: '\'Rubik\', sans-serif',
//   },
//   componentCustomStyles: {
//     // typography
//     h1: {
//       ...commonTypographyStyles,
//       fontWeight: 600,
//       fontSize: '32px',
//       margin: '4px 2px',
//       lineHeight: '40px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '5px 3px',
//       },
//     },
//     h2: {
//       ...commonTypographyStyles,
//       fontWeight: 600,
//       fontSize: '30px',
//       margin: '4px 2px',
//       lineHeight: '38px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '5px 3px',
//       },
//     },
//     h3: {
//       ...commonTypographyStyles,
//       fontWeight: 500,
//       fontSize: '25px',
//       lineHeight: 1,
//       color: themeColors.black,
//       margin: '3px 2px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '4px 3px',
//       },
//     },
//     h4: {
//       ...commonTypographyStyles,
//       fontWeight: 500,
//       fontSize: '20px',
//       margin: '3px 2px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '4px 3px',
//       },
//     },
//     h5: {
//       ...commonTypographyStyles,
//       fontWeight: 500,
//       fontSize: '17px',
//       margin: '3px 2px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '4px 3px',
//       },
//     },
//     h6: {
//       ...commonTypographyStyles,
//       fontSize: '15px',
//       fontWeight: 500,
//       margin: '3px 2px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '4px 3px',
//       },
//     },
//     p: {
//       ...commonTypographyStyles,
//       fontWeight: 500,
//       fontSize: '14px',
//       margin: '3px 2px',
//       [`${mediaQuery.up('sm')}`]: {
//         margin: '4px 3px',
//       },
//     },
//     pre: {
//       ...commonTypographyStyles,
//       fontWeight: 300,
//       fontSize: '15px',
//       color: themeColors.black,
//     },
//     span: {
//       ...commonTypographyStyles,
//       fontSize: '14px',
//       color: themeColors.black,
//     },
//     link: {
//       ...commonTypographyStyles,
//       textDecoration: 'none',
//     },
//     label: {
//       ...commonTypographyStyles,
//       fontWeight: 500,
//       margin: '5px 0',
//     },
//     subtitle1: {
//       ...commonTypographyStyles,
//       fontSize: 14,
//       color: alpha(themeColors.black, 0.7),
//     },
//     subtitle2: {
//       ...commonTypographyStyles,
//       fontWeight: 400,
//       fontSize: 15,
//       color: alpha(themeColors.black, 0.7),
//     },
//     body1: {
//       ...commonTypographyStyles,
//       fontSize: 14,
//     },
//     body2: {
//       ...commonTypographyStyles,
//       fontSize: 14,
//       fontWeight: 300,
//       color: alpha(themeColors.black, 0.2),
//     },
//     caption: {
//       ...commonTypographyStyles,
//       fontSize: 13,
//       textTransform: 'uppercase',
//       color: alpha(themeColors.black, 0.5),
//     },
//     overline: {
//       ...commonTypographyStyles,
//       fontSize: 13,
//       fontWeight: 700,
//       textTransform: 'uppercase',
//     },
//   }
// };

// // global theme for all components
// export const theme = createTheme(commonStylesForAllComponents, customization); 

const fontFamily = 'Rubik, sans-serif';

let theme = createTheme({
  typography: {
    fontFamily
  }
});

theme = createTheme(theme, {
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#1d4ed8'
      },
      name: 'primary'
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#ef4444'
      },
      name: 'error'
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#636B74'
      },
      name: 'secondary'
    }),
    warning: theme.palette.augmentColor({
      color: {
        main: '#e38323'
      },
      name: 'warning'
    })
  }
});

export { theme };
