import { IconButton, Stack } from '@mui/material';
import { PenIcon } from 'views/components/icon';

interface HeaderWithActionProps {
  headerFontSize: string;
  showIcon?: boolean;
  content: string;
  onIconClick?: () => void;
}

const HeaderWithAction: React.FC<HeaderWithActionProps> = ({
  headerFontSize,
  showIcon,
  content,
  onIconClick,
}: HeaderWithActionProps) => {
  return (
    <Stack spacing={3} direction='row' alignItems='center'>
      <span style={{ fontSize: headerFontSize ?? '32px', fontWeight: 500 }}>
        {content}
      </span>
      {showIcon && (
        <IconButton
          aria-label={`edit warehouse detail for ${content}`}
          onClick={onIconClick}
        >
          <PenIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default HeaderWithAction;
