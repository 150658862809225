import { Button, Form, Input } from 'views/components/elements';
import { FormField, Required, Actions, Content } from '../StyledComponent';
import './UserForm.scss';
import {
  removeExtraSpace,
  vehicleOperatorSchema,
} from 'views/shared/utils/form-validator';
import { OperatorFormData } from 'models/Form';
import { ErrorMessage, FormikErrors } from 'formik';
import React, { ChangeEvent } from 'react';
import { NewVehicleOperator } from 'models';

const initialValues: OperatorFormData = {
  name: '',
  email: '',
  phone: '',
  badge_no: '',
  aadhar_no: '',
  date_of_issue: '',
  validity_date: '',
  licence_no: '',
};

interface UserFormProps {
  onSubmit: (newVehicleOperator: NewVehicleOperator) => Promise<void>;
  onCancel: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ onSubmit, onCancel }) => {

  const handleUserFormSubmit = async (formData: OperatorFormData) => {
    const newVehicleOperator: OperatorFormData = {
      name: removeExtraSpace(formData.name),
      email: removeExtraSpace(formData.email) || null,
      phone: removeExtraSpace(formData.phone) || null,
      badge_no: removeExtraSpace(formData.badge_no) || null,
      date_of_issue: formData.date_of_issue || null,
      validity_date: formData.validity_date || null,
      licence_no: removeExtraSpace(formData.licence_no) || null,
      aadhar_no: removeExtraSpace(formData.aadhar_no) || null,
    };

    onSubmit(newVehicleOperator);
  };

  const handleOnChange =
    (
      setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<OperatorFormData>>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<OperatorFormData>>
    ) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      setFieldTouched(name, true);
      setFieldValue(name, value);
    };

  return (
    <Form
      className='user-form'
      onSubmit={handleUserFormSubmit}
      initialValues={initialValues}
      onReset={onCancel}
      validationSchema={vehicleOperatorSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <>
          <Content style={{overflow: 'auto', height: '35vh', padding: '0.5rem', paddingBottom: '1rem'}}>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>
                Name <Required>*</Required>
              </label>
              <Input
                name='name'
                placeholder='FirstName LastName'
                type='text'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={touched.name && errors.name ? 'error-input' : ''}
              />
              <ErrorMessage name='name' component='span' className='error' />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Phone Number <Required>*</Required></label>
              <Input
                name='phone'
                placeholder='Phone Number'
                type='tel'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={touched.phone && errors.phone ? 'error-input' : ''}
              />
              <ErrorMessage name='phone' component='span' className='error' />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Badge</label>
              <Input
                name='badge_no'
                placeholder='Licence Badge'
                type='text'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={
                  touched.badge_no && errors.badge_no ? 'error-input' : ''
                }
              />
              <ErrorMessage name='badge_no' component='span' className='error' />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Licence No</label>
              <Input
                name='licence_no'
                placeholder='Licence No'
                type='text'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={
                  touched.licence_no && errors.licence_no ? 'error-input' : ''
                }
              />
              <ErrorMessage
                name='licence_no'
                component='span'
                className='error'
              />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Issue Date</label>
              <Input
                name='date_of_issue'
                placeholder='Issue Date'
                type='date'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={
                  touched.date_of_issue && errors.date_of_issue
                    ? 'error-input'
                    : ''
                }
              />
              <ErrorMessage
                name='date_of_issue'
                component='span'
                className='error'
              />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Validity Date</label>
              <Input
                name='validity_date'
                placeholder='Validity Date'
                type='date'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={
                  touched.validity_date && errors.validity_date
                    ? 'error-input'
                    : ''
                }
              />
              <ErrorMessage
                name='validity_date'
                component='span'
                className='error'
              />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Aadhar No</label>
              <Input
                name='aadhar_no'
                placeholder='Aadhar No'
                type='text'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={
                  touched.aadhar_no && errors.aadhar_no ? 'error-input' : ''
                }
              />
              <ErrorMessage name='aadhar_no' component='span' className='error' />
            </FormField>
            <FormField style={{ flex: 1 }} className='form-group'>
              <label>Email</label>
              <Input
                name='email'
                placeholder='Email'
                type='email'
                onChange={handleOnChange(setFieldTouched,setFieldValue)}
                className={touched.email && errors.email ? 'error-input' : ''}
              />
              <ErrorMessage name='email' component='span' className='error' />
            </FormField>
          </Content>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>Save</Button>
            <Button
              variant='text'
              color='secondary'
              onClick={onCancel}
              className='cancel-btn'
            >
              Discard
            </Button>
          </Actions>
        </>
      )}
    </Form>
  );
};

export default UserForm;
