import { ApiParams, fetchTransporters, fetchVehicles } from 'gateways/warehouse';
import { NewVehicle, Transporter, Vehicle } from 'models';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { handleError } from 'views/shared/utils/error-utils';
import { create } from 'zustand';

type Action = {
  fetchVehicles: (params: ApiParams) => Promise<EntityResponse<Vehicle[]>>;
  addVehicle: (vehicle: NewVehicle) => void;
  addTransporter: (transporter: Transporter) => void;
  setSelectedVehicle: (selectedVehicle: Vehicle) => void;
  setSelectedTransporter: (selectedTransporter: Transporter) => void;
  fetchTransporters: (params: ApiParams) => Promise<EntityResponse<Transporter[]>>;
  resetVehicleData: () => void;
};

type State = {
  vehicleList: Vehicle[];
  transporterList: Transporter[];
  selectedVehicle: Vehicle;
  selectedTransporter: Transporter;
};

const useVehicleStore = create<State & Action>()(set => ({
  vehicleList: [],
  transporterList: [],
  selectedVehicle: null,
  selectedTransporter: null,
  fetchVehicles: async (params: ApiParams): Promise<EntityResponse<Vehicle[]>> => {
    const apiResponse = await fetchVehicles(params);
    return handleError<Vehicle[]>(apiResponse);
  },
  addVehicle: (vehicle: NewVehicle) => {
    set(state => {
      const { vehicleList: prevVehicles } = state;

      const newVehicles: any = [...prevVehicles, vehicle];
      return { vehicleList: newVehicles, selectedVehicle: vehicle, selectedTransporter: null };
    });
  },
  addTransporter: (transporter: Transporter) => {
    set(state => {
      const { transporterList: prevTransporter } = state;

      const newTransporterList: any = [...prevTransporter, transporter];
      return { transporterList: newTransporterList, selectedTransporter: transporter };
    });
  },
  setSelectedVehicle: (selectedVehicle: Vehicle) => {
    set(state => {
      return {
        ...state,
        selectedVehicle,
      };
    });
  },
  setSelectedTransporter: (selectedTransporter: Transporter) => {
    set(state => {
      return {
        ...state,
        selectedTransporter,
      };
    });
  },
  fetchTransporters: async (params: ApiParams): Promise<EntityResponse<Transporter[]>> => {
    const apiResponse = await fetchTransporters(params);
    return handleError<Transporter[]>(apiResponse);
  },
  resetVehicleData: () => {
    set(() => {
      return {
        vehicleList: [],
        transporterList: [],
        selectedVehicle: null,
        selectedTransporter: null,
      };
    });
  },
}));

export { useVehicleStore };
