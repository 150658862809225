import { Stack, Tab, Tooltip } from '@mui/material';
import { CustomTabs } from 'views/pages/delivery/StyledComponent';
import React, { useState } from 'react';
import { useWarehouseStore } from 'store/warehouse';

interface WarehouseTabProps {
  children?: React.ReactNode;
}

const WarehouseTab: React.FC<WarehouseTabProps> = ({
  children,
}: WarehouseTabProps) => {
  const { warehouseList, setSelectedWarehouse } = useWarehouseStore();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setSelectedTab(value);

    const warehouse = warehouseList[value];

    setSelectedWarehouse(warehouse);
  };

  const tabs = warehouseList.map(({ warehouse_id, warehouse_name }) => (
    <Tooltip title={warehouse_name} key={warehouse_id}>
      <Tab
        className='truncate'
        key={warehouse_id}
        label={warehouse_name}
        aria-label={`Warehouse tab for warehouse - ${warehouse_name}`}
        sx={{
          maxWidth: '225px',
          display: 'inline-block',
          textTransform: 'none',
        }}
        wrapped
      />
    </Tooltip>
  ));

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <CustomTabs
        value={selectedTab}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='warehouse tabs'
        className='warehouse-tab'
      >
        {tabs}
      </CustomTabs>
      {children}
    </Stack>
  );
};

export { WarehouseTab };
