import React, { useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Column, Table } from 'views/components/elements';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import './delivery-list.scss';
import { useWarehouseStore } from 'store/warehouse';
import { useParams } from 'react-router-dom';
import { LogHistory } from 'models/LogHistory';
import { Tooltip } from '@mui/material';
import { useAccountStore } from 'store';

type LogHistoryProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function LogsHistory({ isOpen, onClose }: LogHistoryProps) {
  const { logHistory, fetchLogHistory, hasNextPage, delivery: { invoice_number } } = useWarehouseStore();
  const { user: { name } } = useAccountStore();
  const { delivery_id } = useParams();

  const isInitialFetchCompleted = useRef(false);

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchLogHistory({},delivery_id);
      isInitialFetchCompleted.current = true;
    }
  }, []);

  const columns = [
    {
      field: 'timestamp',
      label: 'DATE & TIME',
      sortable: false,
      format: 'datetime',
      width: 120,
      autoResize: false,
    },
    {
      field: 'user_name',
      label: 'USER NAME',
      width: 120,
      sortable: false,
      autoResize: false,
    },
    {
      field: 'operation',
      label: 'ACTION',
      width: 200,
      sortable: false,
      autoResize: false,
    },
    {
      field: 'description',
      label: 'CHANGE',
      width: 200,
      sortable: false,
      autoResize: false,
    },
  ]as Column<LogHistory>[];

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='confirmation-dialog-title'
        aria-describedby='confirmation-dialog-description'
        fullScreen
      >
        <DialogTitle id='Confirm-dialog-title'>
          <p>Hi {name}!</p>
          <div
            className='head'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div className='truncate' style={{ flex: 1, display: 'flex' }}>
              <Tooltip title={invoice_number} placement='bottom-start'>
                <h2 className='truncate' style={{ flex: '0 1 625px' }}>Log History for invoice No: {invoice_number}</h2>
              </Tooltip>
            </div>
            <DialogActions>
              <Tooltip title='close'>
                <CancelRoundedIcon
                  onClick={onClose}
                  sx={{
                    width: 32,
                    height: 32,
                    cursor: 'pointer',
                    color: '#3460DC',
                  }}
                />
              </Tooltip>
            </DialogActions>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='custom-table-wrapper'>
            {logHistory.length > 0 ? (
              <Table
                rows={logHistory}
                columns={columns}
                stickyHeader
                infiniteScroll
                hasMoreRows={hasNextPage}
              />
            ) : (
              <div>No records found.</div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
