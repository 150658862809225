import { ApiParams, fetchCustomers } from 'gateways/warehouse';
import { Customer } from 'models';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { handleError } from 'views/shared/utils/error-utils';
import { create } from 'zustand';

type Action = {
  setSelectedCustomer: (customer: Customer) => void;
  fetchCustomers: (params: ApiParams) => Promise<EntityResponse<Customer[]>>;
  resetCustomerData: () => void;
};

type State = {
  selectedCustomer: Customer;
};

const useCustomerStore = create<State & Action>()(set => ({
  selectedCustomer: null,
  fetchCustomers: async (params: ApiParams): Promise<EntityResponse<Customer[]>> => {
    const apiResponse = await fetchCustomers(params);
    
    const response = handleError<Customer[]>(apiResponse);

    if (response.failed) {
      return response;
    }

    const list = response.data.map(({customer_id, name, code, shipping_address}) => ({
      customer_id,
      name,
      code,
      shipping_address
    }));
    return {...response, data: list};
  },
  setSelectedCustomer: (selectedCustomer: Customer) => {
    set(() => ({
      selectedCustomer,
    }));
  },
  resetCustomerData: () => {
    set(() => ({
      selectedCustomer: null,
    }));
  },
}));

export { useCustomerStore };
