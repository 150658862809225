import { Button, Form, Input } from 'views/components/elements';
import { FormField, Required, Actions, Content } from '../StyledComponent';
import './UserForm.scss';
import {
  removeExtraSpace,
  transporterValidationSchema,
} from 'views/shared/utils/form-validator';
import { TransporterFormData } from 'models/Form';
import { ErrorMessage, FormikErrors } from 'formik';
import { Transporter } from 'models';
import React, { ChangeEvent } from 'react';

const initialValues: TransporterFormData = { name: '', address: '', phone: '' };

interface TransporterFormProps {
  onSubmit: (newTransporter: Transporter) => Promise<void>;
  onCancel: () => void;
}

const TransporterForm: React.FC<TransporterFormProps> = ({ onSubmit, onCancel }: TransporterFormProps) => {
  const handleTransporterFormSubmit = async (formData: TransporterFormData) => {
    const newTransporter: Transporter = {
      name: removeExtraSpace(formData.name),
      address: removeExtraSpace(formData.address),
      phone: removeExtraSpace(formData.phone),
    };

    onSubmit(newTransporter);
  };

  const handleOnChange =
    (
      setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<TransporterFormData>>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<TransporterFormData>>
    ) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFieldTouched(name, true);
        setFieldValue(name, value);
      };

  return (
    <Form
      className='user-form'
      onSubmit={handleTransporterFormSubmit}
      initialValues={initialValues}
      validationSchema={transporterValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onReset={onCancel}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <Content>
          <FormField className='form-group'>
            <label>
              Transporter Name <Required>*</Required>
            </label>
            <Input
              name='name'
              placeholder='Transporter Name'
              onChange={handleOnChange(setFieldTouched, setFieldValue)}
              className={touched.name && errors.name ? 'error-input' : ''}
            />
            <ErrorMessage name='name' component='span' className='error' />
          </FormField>
          <FormField className='form-group'>
            <label>Transporter Address</label>
            <Input
              name='address'
              placeholder='Transporter Address'
              onChange={handleOnChange(setFieldTouched, setFieldValue)}
              className={touched.address && errors.address ? 'error-input' : ''}
            />
            <ErrorMessage name='address' component='span' className='error' />
          </FormField>
          <FormField className='form-group'>
            <label>Transporter Phone</label>
            <Input
              name='phone'
              placeholder='Transporter Phone'
              onChange={handleOnChange(setFieldTouched, setFieldValue)}
              className={touched.phone && errors.phone ? 'error-input' : ''}
            />
            <ErrorMessage name='phone' component='span' className='error' />
          </FormField>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>Save</Button>
            <Button
              variant='text'
              color='secondary'
              onClick={onCancel}
              className='cancel-btn'
            >
              Discard
            </Button>
          </Actions>
        </Content>
      )}
    </Form>
  );
};

export default TransporterForm;
