import * as Yup from 'yup';

const regexForValidationOfAlphabetsAndSpace = /^[A-Za-z\s]+$/;
const regexForValidationOfAlphaNumericAndSpace = /^[A-Z0-9\s]*$/;
const regexForValidationOfAlphaNumeric = /^[A-z0-9]*$/;

const requiredValidator = (message: string) => {
  return Yup.string().required(message);
};

const nameValidator = (message: string) => {
  return Yup.string().matches(regexForValidationOfAlphabetsAndSpace, message);
};

const nameLengthValidator = (message: string) => {
  return Yup.string().min(4, message);
};

const VehicleValidationSchema = Yup.object().shape({
  number: Yup.string()
    .required('Vehicle number is required.')
    .matches(
      regexForValidationOfAlphaNumericAndSpace,
      'Allowed characters are capital alphabets, numbers, and space.'
    )
    .trim()
    .min(4, 'Vehicle number must be at least 4 characters.')
    .max(20, 'Vehicle number must be at most 20 characters.'),
  name: Yup.string()
    .required('Name is required and cannot be empty.')
    .trim()
    .matches(
      /^[A-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers, and space.'
    )
    .min(4, 'Name must be at least 4 characters.'),
});

const transporterValidationSchema = Yup.object({
  name: Yup.string()
    .required('Transporter name is required.')
    .matches(
      regexForValidationOfAlphabetsAndSpace,
      'Please provide a valid name.'
    )
    .min(4, 'Name must be at least 4 characters.')
    .max(40, 'Name must be at most 40 characters.'),
  address: Yup.string()
    .trim()
    .max(250, 'Address must be at most 250 characters.'),
  phone: Yup.string().matches(
    /^\d{10}$/,
    'Please provide a valid phone number.'
  ),
});

const removeExtraSpace = (value: string) => {
  return value?.trim().replace(/\s+/g, ' ');
};

const nameValidation = Yup.string()
  .required('Name is required.')
  .matches(
    regexForValidationOfAlphabetsAndSpace,
    'Please provide a valid name.'
  )
  .min(4, 'Name must be at least 4 characters.')
  .max(40, 'Name must be at most 40 characters.')
  .test(
    'both-names',
    'Please provide both first name and last name.',
    value => {
      if (!value) return true;
      const parts = value.trim().split(' ');
      return parts.length > 1 && parts.every(part => part.length > 0);
    }
  );

const vehicleOperatorSchema = Yup.object({
  name: nameValidation,
  email: Yup.string().email('Please provide a valid email address.'),
  phone: Yup.string().required('Phone number is required.').matches(
    /^\d{10}$/,
    'Please provide a valid phone number.'
  ),
  licence_no: Yup.string()
    .trim()
    .max(16, 'Licence number must be at most 16 characters.'),
  aadhar_no: Yup.string()
    .matches(
      /^\d{12}$/,
      'Please provide a valid aadhar number.'
    ),
  date_of_issue: Yup.date(),
  validity_date: Yup.date(),
  badge_no: Yup.string()
    .trim()
    .max(16, 'Badge number must be at most 16 characters.'),
});

const deliveryValidationSchema = Yup.object({
  invoice_number: Yup.string()
    .matches(
      /^[a-zA-Z0-9,()-]*$/,
      'Allowed characters are alphabet, number and comma'
    )
    .required('Invoice Number is required')
    .max(250, 'Invoice Number cannot exceed 250 characters'),
  total_cases: Yup.number()
    .typeError('No.of Cases must be a number')
    .required('No.of Cases is required')
    .min(1, 'No.of Cases must be at least 1')
    .max(32766, 'No.of Cases cannot exceed 32766'),
  notes: Yup.string()
    .matches(
      /^[a-zA-Z\s]*$/,
      'Allowed characters are alphabet and space (only in between)'
    )
    .nullable(),
});

const WarehouseValidationSchema = Yup.object({
  warehouse_code: Yup.string()
    .required('Warehouse code is required.')
    .matches(
      regexForValidationOfAlphaNumeric,
      'Allowed characters are alphabet and number'
    )
    .trim()
    .min(1, 'Warehouse code must contain atleast 1 character.')
    .max(10, 'Warehouse code cannot exceed 10 characters.'),
  warehouse_name: Yup.string()
    .required('Warehouse name is required.')
    .matches(
      /^[A-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers and space'
    )
    .trim()
    .min(1, 'Warehouse name must contain atleast 1 character.')
    .max(100, 'Warehouse name cannot exceed 100 characters.'),
});

const LocationValidationSchema = Yup.object({
  location_code: Yup.string()
    .required('Location code is required.')
    .matches(
      regexForValidationOfAlphaNumeric,
      'Allowed characters are alphabet and number'
    )
    .trim()
    .min(1, 'Location code must contain atleast 1 character.')
    .max(10, 'Location code cannot exceed 10 characters.'),
  location_name: Yup.string()
    .required('Location name is required.')
    .matches(
      /^[A-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers and space'
    )
    .trim()
    .min(1, 'Location name must contain atleast 1 character.')
    .max(50, 'Location name cannot exceed 50 characters.'),
  notes: Yup.string()
    .optional()
    .matches(
      /^[A-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers and space'
    )
    .trim()
    .min(1, 'Notes must contain atleast 1 character.'),
});

export {
  requiredValidator,
  nameValidator,
  removeExtraSpace,
  regexForValidationOfAlphabetsAndSpace,
  regexForValidationOfAlphaNumericAndSpace,
  VehicleValidationSchema,
  nameLengthValidator,
  transporterValidationSchema,
  vehicleOperatorSchema,
  deliveryValidationSchema,
  WarehouseValidationSchema,
  LocationValidationSchema,
};
