import { useWarehouseStore } from 'store/warehouse';
import { ChangeEvent, useEffect, useState } from 'react';
import { Vehicle } from 'models';
import { SearchableDropDown, SearchableDropDownProps } from 'views/components/dropdown';
import { useVehicleStore } from 'store/vehicle';
import { useOperatorStore } from 'store/operator';

export function VehicleSearchableDropDown({ required }: { required?: boolean }) {
  const { selectedVehicle, setSelectedVehicle, fetchVehicles, vehicleList } =
    useVehicleStore();
  const { setDelivery } = useWarehouseStore();
  const { resetOperatorData } = useOperatorStore();
  
  const [options, setOptions] = useState<Vehicle[]>([]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const query = event.target.value?.toLowerCase();
    if (!query) {
      return setOptions([]);
    }

    const filteredCreatedVehicles = vehicleList.filter(
      ({name, number, transporter: { name: transporterName }}) =>
        name.toLowerCase().includes(query) ||
        number.toLowerCase().includes(query) ||
        transporterName.toLowerCase().includes(query)
    );

    const { failed, data } = await fetchVehicles({ query });
    setOptions(failed ? [...filteredCreatedVehicles]: [...filteredCreatedVehicles, ...data]);
  };

  const onChange = (_event: any, newValue: Vehicle | null) => {
    if (newValue) {
      setOptions([newValue]);
      setSelectedVehicle(newValue);
      resetOperatorData();

      setDelivery({
        vehicle: { ...newValue },
      });
    }
  };

  useEffect(() => {
    setOptions([...vehicleList]);
  }, [vehicleList]);

  useEffect(() => {
    setOptions(selectedVehicle ? [selectedVehicle]: []);
  }, [selectedVehicle]);

  const props: SearchableDropDownProps<Vehicle> = {
    value: selectedVehicle,
    required: Boolean(required),
    handleSearch,
    onChange,
    options,
    label: 'Vehicle',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      option.number === value.number,
    getOptionLabel: option =>
      `${option.name} - ${option.number} - ${option.transporter?.name}`,
    onBlur: () => setOptions(selectedVehicle ? [selectedVehicle]: []),
  };

  return <SearchableDropDown {...props}/>;
}
