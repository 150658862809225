import { sendRequest, getRequest, postRequest, putRequest } from './gateway';
import { Customer } from 'models/Customer';
import {
  BusinessNameResponse,
  Delivery,
  DeliveryListResponse,
  ErrorResponse,
  Vehicle,
  VehicleOperator,
  DeliveryItem,
  Warehouse,
  Business,
  Location,
  ValidateDelivery,
} from 'models';

const skipSpinnerConfig = {
  skipSpinner: true
};

function fetchCustomers(params: ApiParams) {
  const fetchPromise = getRequest<Customer[]>('/v1/customer/list', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

export interface ApiParams {
  query?: string
  page?: number
  limit?: number
  statuses?: string[]
}

type LogPage = {
  collection: any[]; // Adjust the type based on what your collection contains
  metadata: {
    last_page: number;
  };
};

function fetchDeliveries(params: ApiParams) {
  const fetchPromise = getRequest<DeliveryListResponse>('/v1/delivery/list', {
    params, ...skipSpinnerConfig
  });

  return sendRequest(fetchPromise);
}

async function fetchBusinessName() {
  const businessNameResponse = await getRequest<BusinessNameResponse>('/v1/business', {...skipSpinnerConfig});
  const businessDetailResponse = await getRequest<Business>('/admin/api/v1/business/list/me');
  return {
    businessNameResponse,
    businessDetailResponse
  };
}


function fetchVehicles(params: ApiParams) {
  const fetchPromise = getRequest<Vehicle[]>('/v1/vehicle/list', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

function fetchTransporters(params: ApiParams) {
  const fetchPromise = getRequest<Vehicle[]>('/v1/transporter/list', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

function fetchVehicleOperators(transporter_id: string, params: ApiParams) {
  const fetchPromise = getRequest<VehicleOperator[]>(
    `/v1/user/list?transporter_id=${transporter_id}`, { params, ...skipSpinnerConfig }
  );

  return sendRequest(fetchPromise);
}

function fetchDelivery(delivery_id: string) {
  const fetchPromise = getRequest<Delivery>(`/v1/delivery/${delivery_id}`);

  return sendRequest(fetchPromise);
}

function createDelivery(delivery: Delivery) {
  const postPromise = postRequest<ErrorResponse>('/v1/delivery', delivery);

  return sendRequest(postPromise);
}

function updateDelivery(delivery: Delivery) {
  const putPromise = putRequest<ErrorResponse>(`/v1/delivery/${delivery.delivery_id}`, delivery);

  return sendRequest(putPromise);
}

function updateDeliveryStatus(delivery_id: string, status: string) {
  const putPromise = putRequest<ErrorResponse>(`/v1/delivery/${delivery_id}/${status}`, null);
  return sendRequest(putPromise);
}

function fetchShippingLabel(delivery_id: string) {
  const fetchPromise = getRequest<string>(`/v1/delivery/${delivery_id}/shipping-label`);

  return sendRequest(fetchPromise);
}

function fetchLRReceipt(delivery_id: string) {
  const fetchPromise = getRequest<string>(`/v1/lorry-receipt/${delivery_id}`);
  return sendRequest(fetchPromise);
}

function fetchLogHistory(params: ApiParams, delivery_id): Promise<LogPage> {
  const fetchPromise = getRequest<LogPage>(`/v1/auditlog/list?deliveryId=${delivery_id}`, {params, ...skipSpinnerConfig});
  return sendRequest(fetchPromise);
}

function fetchDeliveryReport(params: ApiParams) {
  const fetchPromise = getRequest<DeliveryItem>('/v1/delivery/report', {params, skipSpinner: false });
  return sendRequest(fetchPromise);
}

function validateDelivery(data: ValidateDelivery) {
  const postPromise = postRequest<ErrorResponse>('/v1/delivery/validate', data);

  return sendRequest(postPromise);
}

function createWarehouse({ warehouse_code, warehouse_name }: Warehouse) {
  const postPromise = postRequest<ErrorResponse>('/v1/warehouse', {
    warehouse_code,
    warehouse_name,
  });

  return sendRequest(postPromise);
}

function updateWarehouse({ warehouse_id, warehouse_name }: Warehouse) {
  const putPromise = putRequest<ErrorResponse>(
    `/v1/warehouse/${warehouse_id}`,
    { warehouse_name }
  );

  return sendRequest(putPromise);
}

function fetchWarehouses() {
  const getPromise = getRequest<ErrorResponse>('/v1/warehouse/list');

  return sendRequest(getPromise);
}

function createLocation({
  warehouse_id,
  location_code,
  location_name,
  zone_id,
  notes,
}: Location) {
  const postPromise = postRequest<ErrorResponse>('/v1/location', {
    warehouse_id,
    location_code,
    location_name,
    zone_id,
    notes,
    is_active: true,
  });

  return sendRequest(postPromise);
}

function updateLocation({ location_id, location_name, notes }: Location) {
  const putPromise = putRequest<ErrorResponse>(`/v1/location/${location_id}`, {
    location_name,
    notes,
  });

  return sendRequest(putPromise);
}

function fetchLocations() {
  const getPromise = getRequest<ErrorResponse>('/v1/location/list');
  return sendRequest(getPromise);
}

function fetchZones(warehouse_id?: number) {
  const warehouseId = warehouse_id ? `?warehouseId=${warehouse_id}`: ''; 
  const getPromise = getRequest<ErrorResponse>(`/v1/zone/list${warehouseId}`);
  return sendRequest(getPromise);
}

export {
  fetchCustomers,
  fetchDeliveries,
  fetchVehicles,
  fetchVehicleOperators,
  createDelivery,
  fetchDelivery,
  updateDelivery,
  fetchShippingLabel,
  fetchLRReceipt,
  updateDeliveryStatus,
  fetchBusinessName,
  fetchLogHistory,
  fetchTransporters,
  fetchDeliveryReport,
  validateDelivery,
  createWarehouse,
  updateWarehouse,
  fetchWarehouses,
  createLocation,
  updateLocation,
  fetchLocations,
  fetchZones,
};
