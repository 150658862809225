import { useEffect } from 'react';
import { useAccountStore } from 'store';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'keypress',
  'scroll',
];

interface AppLogoutProps {
  children: any;
  timeOutInSec?: number;
}

const AppLogout = ({ children, timeOutInSec }: AppLogoutProps) => {
  const autoLogoutTimeInMs = (timeOutInSec ?? 60) * 1000;

  const { user, isAuthenticated } = useAccountStore();
  let timer;

  const handleLogout = () => {
    window.location.replace(user.logoutUrl);
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();

      events.forEach(item => window.removeEventListener(item, resetTimer));

      handleLogout();
    }, autoLogoutTimeInMs);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (isAuthenticated) {
      events.forEach(item => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }
  }, [user, isAuthenticated]);

  return children;
};

export default AppLogout;
