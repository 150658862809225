import { styled } from '@mui/material/styles';

type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type FlexProps = { size?: Size; align?: string; justify?: string };

function getGap(size?: Size) {
  if (size === 'xxs') {
    return 4;
  }

  if (size === 'xs') {
    return 8;
  }

  if (size === 'md') {
    return 24;
  }

  if (size === 'lg') {
    return 48;
  }

  if (size === 'xl') {
    return 64;
  }

  return 16;
}

export const FlexRow = styled('div', { label: 'row', skipSx: true })<FlexProps>(
  props => ({
    display: 'flex',
    columnGap: getGap(props.size),
    alignItems: props.align ?? 'center',
    justifyContent: props.justify ?? 'start',
  })
);

export const FlexColumn = styled('div', {
  label: 'column',
  skipSx: true,
})<FlexProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: getGap(props.size),
  alignItems: props.align ?? 'unset',
  '& h3,& h5': {
    margin: 0,
  },
  '&.small': {
    rowGap: 8,
  },
}));
