import { styled } from '@mui/material/styles';
import { Field } from 'formik';

const MaterialTextarea = styled('textarea')(({ theme }) => ({
  fontSize: 14,
  fontFamily: 'inherit',
  padding: '9.5px 12px',
  borderRadius: 4,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.87)',
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
  resize: 'none',
  boxSizing: 'border-box',
  outline: 'none',
}));

type TextareaProps = React.HTMLProps<HTMLTextAreaElement>;

export default function Textarea(props: TextareaProps) {
  const { name, ...rest } = props;

  return (
    <Field name={name}>
      {({ field }) => <MaterialTextarea {...field} {...rest} />}
    </Field>
  );
}
