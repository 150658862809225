import { ApiParams, fetchVehicleOperators } from 'gateways/warehouse';
import { NewVehicleOperator, VehicleOperator } from 'models';
import { create } from 'zustand';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { handleError } from 'views/shared/utils/error-utils';

type Action = {
  addOperator: (operator: NewVehicleOperator) => void;
  setSelectedOperator: (operator?: VehicleOperator) => void;
  fetchVehicleOperator: (
    transporterId: string,
    params: ApiParams
  ) => Promise<EntityResponse<VehicleOperator[]>>;
  resetOperatorData: () => void;
};

type State = {
  vehicleOperatorList: VehicleOperator[];
  selectedOperator: VehicleOperator;
};

const useOperatorStore = create<State & Action>()(set => ({
  vehicleOperatorList: [],
  selectedOperator: null,
  addOperator: (operator: NewVehicleOperator) => {
    set(state => {
      const { vehicleOperatorList: prevOperators } = state;

      const newOperators: any = [...prevOperators, operator];
      return {
        ...state,
        vehicleOperatorList: newOperators,
        selectedOperator: operator,
      };
    });
  },
  fetchVehicleOperator: async (id: string, params: ApiParams) => {
    const apiResponse = await fetchVehicleOperators(id, params);
    return handleError<VehicleOperator[]>(apiResponse);
  },
  setSelectedOperator: (selectedOperator: VehicleOperator) => {
    set(state => {
      return {
        ...state,
        selectedOperator,
      };
    });
  },
  resetOperatorData: () => {
    set(() => {
      return {
        vehicleOperatorList: [],
        selectedOperator: null,
      };
    });
  },
}));

export { useOperatorStore };
