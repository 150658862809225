import { dateTimeFormat } from './string-utils'; 

export const generateCsvData = (reportCsvData) => {
  return reportCsvData.length > 0
    ? reportCsvData.map((delivery) => ({
      CreatedDate: dateTimeFormat(delivery.created_date_time, 'timestamp'),
      CustomerCode: delivery.customer_code,
      CustomerName: delivery.customer_name,
      CustomerLocation: delivery.customer_shipping_address,
      VehicleName: delivery.vehicle_name,
      VehicleNumber: delivery.vehicle_number,
      VehicleTransporterName: delivery.transporter_name,
      VehicleOperatorName: delivery.vehicle_operator_name,
      VehicleOperatorEmail: delivery.vehicle_operator_email,
      VehicleOperatorPhone: delivery.vehicle_operator_phone,
      InvoiceNo: delivery.invoice_number,
      ConsignmentNo: delivery.consignment_number,
      Status: delivery.status,
      ReceivedDate: dateTimeFormat(delivery.received_date, 'timestamp'),
      ReceivedCases: delivery.received_count,
      PrintedDate: dateTimeFormat(delivery.printed_date, 'timestamp'),
      PrintedCases: delivery.printed_count,
      ShippedDate: dateTimeFormat(delivery.shipped_date, 'timestamp'),
      ShippedCases: delivery.shipped_count,
      DeliveredDate: dateTimeFormat(delivery.delivered_date, 'timestamp'),
      DeliveredCases: delivery.delivered_count,
      PodAttached: delivery.pod_attached,
      ConsigneePodAttached: delivery.consignee_pod_attached,
    }))
    : [];
};
