import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from './Button';
import { useState } from 'react';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: 2,
    boxShadow: '0 2px 5px 4px rgba(167, 164, 164, 0.25);',
    padding: '4px 0',
    minWidth: 100,
  },
}));

interface Item {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

type MenuButtonProps = ButtonProps & { menus: Item[] };

export default function MenuButton(props: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createMenuClickHandler = (menu: Item) => () => {
    handleClose();
    if (menu.onClick) {
      menu.onClick();
    }
  };

  return (
    <>
      <Button {...props} onClick={handleClick} />
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.menus.map(menu => (
          <MenuItem
            disableRipple
            onClick={createMenuClickHandler(menu)}
            key={menu.label}
            disabled={menu.disabled}
          >
            {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
            <ListItemText>{menu.label}</ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
