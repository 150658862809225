import { Delivery, DeliveryListView } from 'models';

enum DELIVERY_STATUS {
  received = 1,
  picked,
  packed,
  verified,
  printed,
  shipped,
  delivered,
}

const getInitialDelivery = (): Delivery => {
  return {
    invoice_number: '',
    consignment_number: null,
    customer: {
      customer_id: '',
      name: '',
    },
    shipping_address: '',
    total_cases: 0,
    notes: '',
    sku: '',
    vehicle: {
      vehicle_id: '',
      name: '',
      transporter: {
        transporter_id: '',
        name: '',
        address: '',
        phone: '',
      },
    },
    vehicle_operator: {
      vehicle_operator_id: '',
      name: '',
      email: '',
      phone: '',
      badge_no: '',
      aadhar_no: '',
      date_of_issue: '',
      validity_date: '',
      licence_no: '',
    },
    delivery_id: '',
    business_id: '',
    status: '',
    created_date_time: '',
    lines: [],
    line_status: {
      printed: 0,
      shipped: 0,
      delivered: 0,
    },
  };
};

const transformDelivery = (deliveryList: Delivery[]): DeliveryListView[] => {
  return deliveryList.map(delivery => ({
    delivery_id: delivery.delivery_id,
    customer_name: delivery.customer.name,
    shipping_address:
      delivery.delivery_location ?? delivery.customer.shipping_address,
    invoice_number: delivery.invoice_number,
    total_cases: delivery.total_cases,
    sku: delivery.sku,
    vehicle_name: delivery.vehicle?.name,
    created_date: delivery.created_date_time,
    status: delivery.status,
    pod_image_link: delivery.pod_image_link,
    lr_image_link: delivery.lr_image_link
  }));
};

export type EntityResponse<T> = {
  error: string;
  data: T;
  failed: boolean;
};

export {
  transformDelivery,
  getInitialDelivery,
  DELIVERY_STATUS,
};
