import { styled } from '@mui/material/styles';

const Tab = styled('div')({
  display: 'flex',
  columnGap: 32,
  '& a': {
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: '500',
    color: '#1d4ed8',
    paddingBottom: 12,
    '&.active': {
      color: '#0f172a',
      borderBottom: '2px solid #0f172a',
    }
  }
});

export default Tab;
