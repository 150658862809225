import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Popover, PopoverProps } from '@mui/material';

// PopoverWithArrow Component
const PopoverWithArrow = (
  popoverProps: Omit<PopoverProps, 'anchorOrigin' | 'transformOrigin'>
) => (
  <Popover
    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'left', vertical: -5 }}
    slotProps={{
      paper: {
        sx: {
          overflow: 'visible',
          padding: '20px !important',
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 15,
            width: 10,
            height: 10,
            backgroundColor: 'inherit',
            transform: 'translateY(-50%) rotate(45deg)',
            boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
          },
        },
      },
    }}
    {...popoverProps}
  />
);

interface WhitePopoverProps {
  content: React.ReactNode;
  children: React.ReactElement;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
}

// WhitePopover Component
const WhitePopover: React.FC<WhitePopoverProps> = ({ content, children, anchorEl, setAnchorEl }) => {
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener mouseEvent={false} onClickAway={handlePopoverClose}>
      <div>
        <span onClick={handlePopoverOpen}>{children}</span>
        <PopoverWithArrow
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopoverClose}
        >
          {content}
        </PopoverWithArrow>
      </div>
    </ClickAwayListener>
  );
};

export default WhitePopover;
