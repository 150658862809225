import { NavLink } from 'react-router-dom';
import './SideNavigationBar.scss';
import { SettingsIcon } from '../icon';

function SideNavigationBar() {
  return (
    <div className="nav-sidebar">
      <NavLink to="/delivery">
        <span className="material-symbols-outlined">package_2</span>
        <span>Deliveries</span>
      </NavLink>
      <NavLink to="/settings">
        <SettingsIcon/>
        <span>Settings</span>
      </NavLink>
    </div>
  );
}

export default SideNavigationBar;
