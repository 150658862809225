import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import { CancelRounded } from '@mui/icons-material';
import WarningCard from './WarningCard';

interface PdfViewerProps {
  pdfTitle: string;
  pdfData: string;
  open: boolean;
  handleClose: () => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  pdfTitle,
  pdfData,
  open,
  handleClose,
}: PdfViewerProps) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let char = 0; char < byteCharacters.length; ++char) {
      byteNumbers[char] = byteCharacters.charCodeAt(char);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  useEffect(() => {
    if (pdfData) {
      try {
        const blob = base64ToBlob(pdfData, 'application/pdf');
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);

        return () => URL.revokeObjectURL(url);
      } catch (error) {
        return handleError(
          'Error while displaying the PDF file. Please try again.'
        );
      }
    }
  }, [pdfData]);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setIsErrorPopUpOpen(true);
  };

  const onClose = () => {
    URL.revokeObjectURL(pdfUrl);
    setPdfUrl(null);
    handleClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
      />
      <DialogActions
        style={{ justifyContent: 'space-between', padding: '6px' }}
      >
        <h2 style={{ marginLeft: '6px' }}>{pdfTitle}</h2>
        <IconButton
          edge='end'
          color='inherit'
          onClick={onClose}
          aria-label='close'
          style={{ margin: '0' }}
        >
          <CancelRounded
            sx={{
              width: 32,
              height: 32,
              cursor: 'pointer',
              color: '#3460DC',
            }}
          />
        </IconButton>
      </DialogActions>
      <DialogContent style={{ overflow: 'hidden', padding: '0px' }}>
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            title='PDF'
            style={{ width: '100%', height: '100%' }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PdfViewer;
