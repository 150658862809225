import { Claim } from '../models/Claim';
import { getRequest, sendRequest } from './gateway';

function getUserClaims() {
  const fetchPromise = getRequest<Claim[]>('/bff/user');

  return sendRequest(fetchPromise);
}

export { getUserClaims };
