import React, { useState, memo, useCallback } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
} from '@mui/material';
import { AccordionArrowIcon, PenIcon } from '../icon';
import { TitleText } from 'views/pages/delivery/StyledComponent';
import IOSSwitch from './CustomSwitch';

interface CustomAccordionProps {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  switchChecked?: boolean;
  onSwitchChange?: (checked: boolean) => void;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  title,
  children,
  defaultExpanded = false,
  switchChecked = false,
  onSwitchChange,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [checked, setChecked] = useState(switchChecked);

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const isChecked = event.target.checked;
      setChecked(isChecked);
      onSwitchChange?.(isChecked);
    },
    [onSwitchChange]
  );

  const handleAccordionChange = useCallback(() => {
    setExpanded(prevExpanded => !prevExpanded);
  }, []);

  const StopPropagationButton = ({ children, onClick }) => (
    <IconButton
      sx={{ ml: 1 }}
      onClick={e => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      {children}
    </IconButton>
  );

  return (
    <Box mb={3}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        sx={{
          border: '1px solid #EDF2FF',
          borderRadius: '10px',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: expanded ? '56px !important' : 'auto',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<AccordionArrowIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#EDF2FF',
            paddingRight: 2,
            height: '56px !important',
          }}
        >
          <TitleText
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              color: '#070417',
              fontSize: '24px !important',
              lineHeight: '28.44px !important',
            }}
          >
            {title}
            <StopPropagationButton onClick={e => e.stopPropagation()}>
              <PenIcon />
            </StopPropagationButton>
          </TitleText>
          <Box onClick={e => e.stopPropagation()}>
            <IOSSwitch
              checked={checked}
              onChange={handleSwitchChange}
              sx={{ m: 2 }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>{expanded && <Box>{children}</Box>}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(
  CustomAccordion,
  (prevProps, nextProps) =>
    prevProps.title === nextProps.title &&
    prevProps.children === nextProps.children &&
    prevProps.defaultExpanded === nextProps.defaultExpanded &&
    prevProps.switchChecked === nextProps.switchChecked &&
    prevProps.onSwitchChange === nextProps.onSwitchChange
);
