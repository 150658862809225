import './UserForm.scss';
import {
  Button,
  Form,
  Input,
  PopoverWithArrow,
} from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  CreateNewLink,
} from '../StyledComponent';
import TransporterForm from './Transporter';
import { ChangeEvent, useState } from 'react';
import { NewVehicle, Transporter } from 'models';
import {
  removeExtraSpace,
  VehicleValidationSchema,
} from 'views/shared/utils/form-validator';
import { TransporterSearchableDropDown } from '../dropdowns/TransporterSearchableDropDown';
import { VehicleFormData } from 'models/Form';
import { useVehicleStore } from 'store/vehicle';
import { ErrorMessage, FormikErrors } from 'formik';

const initialValues = {
  name: '',
  number: '',
};

interface VehicleFormProps {
  onSubmit: (newVehicle: NewVehicle) => Promise<void>;
  onClose: () => void;
  onTransporterFormSubmit: (newTransporter: Transporter) => Promise<boolean>;
}

const VehicleForm = ({ onSubmit, onTransporterFormSubmit, onClose }: VehicleFormProps) => {
  const [anchorElForTransporterForm, setAnchorElForTransporterForm] =
    useState<HTMLElement | null>(null);

  const { selectedTransporter } = useVehicleStore();

  const handleTransporterForm = async (newTransporter: Transporter) => {
    const failed = await onTransporterFormSubmit(newTransporter);

    if (failed) {
      return;
    }

    setAnchorElForTransporterForm(null);
  };

  const handleTransporterFormCancel = () => {
    setAnchorElForTransporterForm(null);
  };

  const handleVehicleFormSubmit = async (formData: VehicleFormData) => {
    const newVehicle: NewVehicle = {
      name: removeExtraSpace(formData.name),
      number: removeExtraSpace(formData.number),
      transporter: {
        ...selectedTransporter,
      }
    };

    onSubmit(newVehicle);
  };

  const handleOnChange =
    (
      setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<VehicleFormData>>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<VehicleFormData>>
    ) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFieldTouched(name, true);
        setFieldValue(name, value);
      };

  return (
    <Form
      className='user-form'
      initialValues={initialValues}
      onSubmit={handleVehicleFormSubmit}
      validationSchema={VehicleValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <Content>
          <FormField className='form-group'>
            <label>
              Vehicle No. <Required>*</Required>
            </label>
            <Input
              name='number'
              placeholder='Vehicle No.'
              className={touched.number && errors.number ? 'error-input' : ''}
              onChange={handleOnChange(setFieldTouched, setFieldValue)}
              required
            />
            <ErrorMessage name='number' component='span' className='error' />
          </FormField>
          <FormField className='form-group'>
            <label>
              Vehicle Name <Required>*</Required>
            </label>
            <Input
              name='name'
              placeholder='Vehicle Name'
              className={touched.name && errors.name ? 'error-input' : ''}
              onChange={handleOnChange(setFieldTouched, setFieldValue)}
              required
            />
            <ErrorMessage name='name' component='span' className='error' />
          </FormField>
          <FormField>
            <label>
              Transporter <Required>*</Required>
            </label>
            <TransporterSearchableDropDown required/>
            <PopoverWithArrow
              anchorEl={anchorElForTransporterForm}
              setAnchorEl={setAnchorElForTransporterForm}
              content={
                <TransporterForm
                  onSubmit={handleTransporterForm}
                  onCancel={handleTransporterFormCancel}
                ></TransporterForm>
              }
            >
              <CreateNewLink>Create New</CreateNewLink>
            </PopoverWithArrow>
          </FormField>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>Save</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClose}
            >
              Discard
            </Button>
          </Actions>
        </Content>)}
    </Form>
  );
};

export default VehicleForm;
