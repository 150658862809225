import { Button, Input } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  removeExtraSpace,
  WarehouseValidationSchema,
} from 'views/shared/utils/form-validator';
import { SettingsFormProps, WarehouseFormData } from 'models/Form';
import { Warehouse } from 'models';
import './index.scss';
import '../../delivery/forms/UserForm.scss';
import { ErrorMessage } from 'formik';
import { useWarehouseStore } from 'store/warehouse';

const initialValues: WarehouseFormData = {
  warehouse_code: '',
  warehouse_name: '',
};

type WarehouseFormProps = SettingsFormProps<Warehouse>;

const WarehouseForm: React.FC<WarehouseFormProps> = ({
  edit,
  value,
  onSubmit,
  onClose,
}: WarehouseFormProps) => {
  const { createWarehouse, updateWarehouse } = useWarehouseStore();

  const handleSubmit = async ({
    warehouse_code,
    warehouse_name,
  }: WarehouseFormData) => {
    const warehouseData = {
      ...value,
      warehouse_code: removeExtraSpace(warehouse_code),
      warehouse_name: removeExtraSpace(warehouse_name),
    } as Warehouse;

    const { failed, error } = await (edit ? updateWarehouse : createWarehouse)(
      warehouseData
    );

    onSubmit(failed, error);
  };

  return (
    <PopUpForm
      className='user-form form'
      initialValues={edit ? value : initialValues}
      validationSchema={WarehouseValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={onClose}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <Content>
          <FormField className='form-group'>
            <label>
              Warehouse Code <Required>*</Required>
            </label>
            <Input
              name='warehouse_code'
              placeholder='Warehouse Code'
              className={
                touched.warehouse_code && errors.warehouse_code
                  ? 'error-input'
                  : ''
              }
              disabled={edit}
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='warehouse_code'
              component='span'
              className='error'
            />
          </FormField>
          <FormField className='form-group'>
            <label>
              Warehouse Name <Required>*</Required>
            </label>
            <Input
              name='warehouse_name'
              placeholder='Warehouse Name'
              className={
                touched.warehouse_name && errors.warehouse_name
                  ? 'error-input'
                  : ''
              }
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='warehouse_name'
              component='span'
              className='error'
            />
          </FormField>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>{edit ? 'Update' : 'Create'}</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClose}
            >
              Discard
            </Button>
          </Actions>
        </Content>
      )}
    </PopUpForm>
  );
};

export default WarehouseForm;
