import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmDialogProps = {
  confirmationTitle: string;
  confirmationMsg: string;
  onClickConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export default function ConfirmDialog({
  isOpen,
  onClose,
  confirmationMsg,
  confirmationTitle,
  onClickConfirm,
}: ConfirmDialogProps) {
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='confirmation-dialog-title'
        aria-describedby='confirmation-dialog-description'
      >
        <DialogTitle id='Confirm-dialog-title'>{confirmationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id='Confirm-dialog-description'>
            {confirmationMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='btn-container flex-justify-end'>
          <Button
            variant='contained'
            onClick={onClickConfirm}
            style={{ textTransform: 'capitalize', marginRight: '24px' }}
          >
            Confirm
          </Button>
          <Button
            variant='text'
            color='secondary'
            className='cancel-btn'
            onClick={onClose}
            style={{ textTransform: 'capitalize' }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
