import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { Field } from 'formik';

const StyledInput = styled(OutlinedInput)(() => ({
  ['& .MuiInputBase-input']: {
    padding: '9.5px 12px',
    height: 'unset',
  },
  fontSize: '14px',
  lineHeight: 'normal',
}));

export default function Input(props: OutlinedInputProps) {
  const { name, ...rest } = props;

  return (
    <Field name={name}>
      {({ field }) => <StyledInput {...field} {...rest} />}
    </Field>
  );
}
