import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button, { ButtonOwnProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Divider, { DividerProps } from '@mui/material/Divider';
import { Icon } from '../icon';
import useUniqueKey from '../../../hooks/useUniqeKey';
import { Autocomplete } from '@mui/material';
import { ChangeEventHandler, SyntheticEvent, FocusEventHandler, useState, useEffect } from 'react';
import DebounceInput from '../debounce-input';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    // fontFamily: theme.general.fontFamily,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      // color: theme.colors.primary,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const StyledButton = styled(Button)(() => `
    text-transform: none;
`);

interface DropdownOptionMenuItem extends MenuItemProps {
    label?: React.ReactNode;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    itemType?: 'menuItem';
}

interface DropdownOptionDivider extends DividerProps {
    itemType?: 'divider';
}

type DropdownOption = DropdownOptionMenuItem | DropdownOptionDivider;

interface DropdownProps {
    options: DropdownOption[];
    menuProps?: MenuProps;
    buttonProps?: ButtonOwnProps;
    icon?: React.ReactNode;
    label?: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  menuProps,
  buttonProps,
  label,
  icon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const uniqueKeys = useUniqueKey(options.length);
  const [ id ] = useUniqueKey(1);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        variant="contained"
        {...buttonProps}
        onClick={handleClick}
        id={`${id}-button`}
        startIcon={icon}
        endIcon={<Icon icon='expand_more_icon' iconValue='expand_more' />}
      >
        {label}
      </StyledButton>
      <StyledMenu
        {...menuProps}
        id={`${id}-menu`}
        MenuListProps={{
          ...menuProps?.MenuListProps,
          'aria-labelledby': `${id}-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          options.map((option, index) => 
            option.itemType === 'divider'
              ? <Divider key={uniqueKeys[index]} id={uniqueKeys[index]} {...option} sx={{ my: 0.5, ...option.sx }} />
              : <MenuItem key={uniqueKeys[index]} id={uniqueKeys[index]} {...option}>
                {option.startIcon}
                {option.label}
                {option.endIcon}
              </MenuItem>
          )
        }
      </StyledMenu>
    </div>
  );
};

export interface SearchableDropDownProps<T> {
  value: T | null;
  handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (_event: SyntheticEvent, newValue: T | null) => void;
  onBlur: FocusEventHandler<HTMLDivElement>;
  getOptionLabel: (option: T) => string;
  options: T[];
  label: string;
  required: boolean;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  debounce: number;
  disabled?: boolean;
}


const StyledDebounce = styled(DebounceInput)(() => ({
  ['& input']: {
    fontSize: '14px',
    lineHeight: 'normal',
  },
  ['& input::placeholder']: {
    color: '#bcbcbc',
  },
}));

export function SearchableDropDown<T>({
  handleSearch,
  label,
  required,
  debounce,
  value,
  disabled=false,
  ...autoCompleteProps
}: SearchableDropDownProps<T>) {
  const [searchEnded, setSearchEnded] = useState(false);

  const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (event) => {
    const { value: query } = event.target;

    await handleSearch(event);
    setSearchEnded(Boolean(query));
  };

  useEffect(() => {
    setSearchEnded(Boolean(value));
  }, [value]);

  const renderInput = (params) => <StyledDebounce
    {...params}
    handleSearch={handleInputChange}
    placeholder={`Search ${label}`}
    required={required}
    debounce={debounce}
  />;

  return (
    <Autocomplete
      noOptionsText={ searchEnded ? 'No match found' : `Enter ${label.toLowerCase()} detail`}
      filterOptions={x => x}
      value={value}
      disabled={disabled}
      {...autoCompleteProps}
      renderInput={renderInput}
      size='small'
    />
  );
}

export default Dropdown;
