import { Alert, AlertColor, Slide, Snackbar } from '@mui/material';

interface WarningCardProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: AlertColor;
  durationInSeconds?: number;
}

const SlideTransition = (props) => {
  return <Slide {...props} direction='down' />;
};

const WarningCard = ({ open, onClose, message, severity, durationInSeconds }: WarningCardProps) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={(durationInSeconds || 6) * 1000}
    >
      <Alert onClose={onClose} severity={severity ?? 'error'} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default WarningCard;
