import MatTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {
  TableCellProps,
  tableCellClasses,
} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import './Table.scss';
import {
  currencyFormat,
  dateTimeFormat,
} from '../../shared/utils/string-utils';
import { useRef } from 'react';

const CheckboxCell = styled((props: TableCellProps) => (
  <TableCell padding='checkbox' {...props} />
))(() => ({
  border: 0,
}));

const BasicTable = styled(TableContainer)({
  border: 'solid 1px #eeeff2',
  borderRadius: 10,
  '& .MuiTableRow-head': {
    backgroundColor: '#f6f9fb',
    borderBottom: 'solid 1px #eeeff2',
  },
});

const Cell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
  fontSize: 12,
  padding: '0 16px',
  borderBottom: 0,
  color: '#0f172a',
}));

const ScrollFixCell = styled(Cell)(() => ({
  padding: '0',
  width: '4px',
}));

const Row = styled(TableRow)(({ hover }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f6f9fb',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  height: '48px',
  ...(hover && {
    '&:hover': {
      backgroundColor: 'rgba(246, 249, 251, 0.6) !important',
      cursor: 'pointer',
    },
  }),
}));

interface Column<T> {
  field: string;
  label: React.ReactNode;
  numeric?: boolean;
  sortable?: boolean;
  width?: number | string;
  valueGetter?: (row: T, index: number) => JSX.Element;
  format?: 'date' | 'time' | 'datetime' | 'currency';
  autoResize?: boolean;
}

type SortDirection = 'asc' | 'desc' | null | undefined;

interface TableProps<T> {
  stickyHeader?: boolean;
  columns: Column<T>[];
  onSortChange?: (field: string, order: SortDirection) => void;
  sortConfig?: { field: string; direction: 'asc' | 'desc' };
  rows: T[];
  sortingOrder?: SortDirection;
  sortingField?: string;
  onNextPage?: () => void;
  hasMoreRows?: boolean;
  infiniteScroll?: boolean;
  selectable?: boolean;
  onSelectionChange?: (rows: T[]) => void;
  selected?: T[];
}

function createSortHandler<T>(props: TableProps<T>, column: Column<T>) {
  return () => {
    const isAsc =
      props.sortingField === column.field && props.sortingOrder === 'asc';
    props.onSortChange(column.field, isAsc ? 'desc' : 'asc');
  };
}

function RenderHeadCell<T>(column: Column<T>, props: TableProps<T>) {
  return (
    <Cell
      key={column.field}
      align={column.numeric || column.format === 'currency' ? 'right' : 'left'}
      sortDirection={
        props.sortingField == column.field ? props.sortingOrder : false
      }
      width={column.width}
    >
      {column.sortable ? (
        <TableSortLabel
          active={props.sortingField === column.field}
          direction={
            props.sortingField === column.field ? props.sortingOrder : 'asc'
          }
          onClick={createSortHandler(props, column)}
        >
          {column.label}
        </TableSortLabel>
      ) : (
        column.label
      )}
    </Cell>
  );
}

function RenderCellValue<T>(column: Column<T>, row: T, index: number) {
  let value: any;

  if (column.valueGetter) {
    value = column.valueGetter(row, index);
  } else {
    value = row[column.field];
  }

  if (column.format === 'currency') {
    if (typeof value === 'number') {
      value = currencyFormat(value);
    }
  } else if (column.format) {
    if (typeof value === 'string') {
      value = dateTimeFormat(value, column.format);
    }
  }

  return value;
}

interface HeadProps {
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedCount?: number;
  rowCount?: number;
}

function CustomTableHead<T>(props: TableProps<T> & HeadProps) {
  const { selectedCount, rowCount, onSelectAllClick } = props;
  return (
    <TableHead>
      <Row>
        {props.selectable && (
          <CheckboxCell>
            <Checkbox
              color='primary'
              indeterminate={selectedCount > 0 && selectedCount < rowCount}
              checked={rowCount > 0 && selectedCount === rowCount}
              onChange={onSelectAllClick}
            />
          </CheckboxCell>
        )}
        {props.columns.map(column => RenderHeadCell(column, props))}
        {props.stickyHeader && <ScrollFixCell />}
      </Row>
    </TableHead>
  );
}

interface RowProps<T> {
  row: T;
  index: number;
  onClick?: (item: T) => void;
  isSelected?: boolean;
}

function CustomRow<T>(props: TableProps<T> & RowProps<T>) {
  return (
    <Row
      onClick={() => props.onClick && props.onClick(props.row)}
      hover={props.selectable || !!props.onSelectionChange}
    >
      {props.selectable && (
        <CheckboxCell>
          <Checkbox color='primary' checked={props.isSelected} />
        </CheckboxCell>
      )}
      {props.columns.map(column => (
        <Cell
          key={column.field + props.index}
          align={
            column.numeric || column.format === 'currency' ? 'right' : 'left'
          }
          width={column.width}
          style={
            column.autoResize ?? true ? undefined : { maxWidth: column.width }
          }
          className='truncate'
        >
          {RenderCellValue(column, props.row, props.index)}
        </Cell>
      ))}
    </Row>
  );
}

function getRowClickHandler<T>(props: TableProps<T>) {
  return (item: T) => {
    const selected = props.selected || [];

    const selectedIndex = selected.indexOf(item);
    let newSelected: readonly T[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (props.onSelectionChange) {
      props.onSelectionChange(newSelected.slice());
    }
  };
}

function getSelectAllClickHandler<T>(props: TableProps<T>) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelected = props.rows.slice();
    if (!event.target.checked) {
      newSelected = [];
    }

    if (props.onSelectionChange) {
      props.onSelectionChange(newSelected);
    }
  };
}

function StickyTable<T>(props: TableProps<T>) {
  const selected = props.selected || [];

  const isSelected = (item: T) => selected.indexOf(item) !== -1;

  const bodyWrapper = useRef<HTMLDivElement>(null);
  const debounceScroll = useRef(0);

  const handleScroll = () => {
    if (props.infiniteScroll && props.hasMoreRows) {
      const el = bodyWrapper.current;
      const scrollOffset = el.scrollHeight - el.clientHeight - el.scrollTop;
      if (scrollOffset < 200) {
        if (!debounceScroll.current) {
          debounceScroll.current = scrollOffset;

          if (props.onNextPage) {
            props.onNextPage();
          }

          setTimeout(() => {
            debounceScroll.current = 0;
          }, 500);
        }
      }
    }
  };

  return (
    <>
      <div className='head-wrapper'>
        <MatTable>
          <CustomTableHead
            {...props}
            selectedCount={selected.length}
            rowCount={props.rows.length}
            onSelectAllClick={getSelectAllClickHandler(props)}
          />
        </MatTable>
      </div>
      <div className='body-wrapper' onScroll={handleScroll} ref={bodyWrapper}>
        <TableContainer>
          <MatTable>
            <TableBody>
              {props.rows.map((row, index) => (
                <CustomRow
                  row={row}
                  key={index}
                  index={index}
                  {...props}
                  onClick={getRowClickHandler(props)}
                  isSelected={isSelected(row)}
                />
              ))}
            </TableBody>
          </MatTable>
        </TableContainer>
      </div>
    </>
  );
}

function Table<T>(props: TableProps<T>) {
  const selected = props.selected || [];

  const isSelected = (item: T) => selected.indexOf(item) !== -1;

  if (props.stickyHeader) {
    return <StickyTable {...props} />;
  }

  return (
    <BasicTable>
      <MatTable>
        <CustomTableHead
          {...props}
          selectedCount={selected.length}
          rowCount={props.rows.length}
          onSelectAllClick={getSelectAllClickHandler(props)}
        />
        <TableBody>
          {props.rows.map((row, index) => (
            <CustomRow
              row={row}
              key={index}
              index={index}
              {...props}
              onClick={getRowClickHandler(props)}
              isSelected={isSelected(row)}
            />
          ))}
        </TableBody>
      </MatTable>
    </BasicTable>
  );
}

export { Table };

export type { Column };
